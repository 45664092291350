/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable curly */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import BackButton from 'components/Button/BackButton'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IndexNavbar from 'components/common/Navbars/IndexNavbar'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// components
import { Box, Button, Link as LinkUI, Select } from '@chakra-ui/react'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import _ from 'lodash'

// Dashboard All Station.
export default function ManuDashboard({ sidebarStatus, setIsShowSidebar }) {
  const [isLoading, setIsLoading] = useState(false)
  const [fetchingNewOne, setFetchingNewOne] = useState(true)
  const [allowedDashboard, setAllowedDashboard] = useState()
  const stations = useSelector((state) => state.station)
  const me = useSelector((state) => state.me)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (fetchingNewOne) {
      dispatch(actions.stationAll())
      setIsLoading(true)
      setFetchingNewOne(!fetchingNewOne)
    }
    return () => {}
  }, [fetchingNewOne])

  // ตรวจสอบว่า User ที่เข้ามามี Permission ที่เข้าสถานีไหนได้บ้าง
  // ตอนนี้ยังเก็บเป็น Text อยู่ เก็บเฉพาะใน User ไม่ได้เก็บในสถานี
  // ถ้าอนุญาติหมดจะเป็น ALLAREA
  // TODO: ถ้าเป็น Array จะดีมาก
  useEffect(() => {
    if (me && me._id) {
      if (_.size(stations.arr)) {
        if (_.includes(me.duty, 'ALLAREA')) {
          let allowedStationStack = stations.arr
          setAllowedDashboard(allowedStationStack)
        } else {
          let allowedStation = _.filter(
            stations.arr,
            (station, index) => station._id === me.duty[index]
          )
          setAllowedDashboard(allowedStation)
        }
      }
    }
    return () => {}
  }, [stations, me])

  return isLoading ? (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('dashboard.dashboardMonitor')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              เมนูแดชบอร์ด
            </h2>
          </div>
        </div>
        <Button className="py-4 px-4">
          <BackButton />
        </Button>
      </div>
      {allowedDashboard && _.size(allowedDashboard) ? (
        <div>
          <div className="flex justify-between p-4">
            <div className="relative w-full  mb-3"></div>
            <div className="flex justify-items-end">
              <Button
                colorScheme="gray"
                variant="solid"
                size="sm"
                onClick={() => setIsShowSidebar(!sidebarStatus)}
              >
                {t('dashboard.toggleSidebar')}
              </Button>
            </div>
          </div>{' '}
          <div className="p-5 w-full">
            {_.map(allowedDashboard, (station, index) => (
              <Box
                borderRadius="lg"
                borderWidth="1px"
                padding="4"
                className="mb-6 shadow-sm "
                key={index}
              >
                <Link to={'/dashboards/board/' + station._id}>
                  <h2 className="text-xl font-bold ">
                    {' '}
                    <LinkUI className="font-sans font-bold">
                      {station.name}{' '}
                    </LinkUI>{' '}
                  </h2>{' '}
                </Link>
                <h5 className="text-base text-gray-700 font-sans">
                  {' '}
                  {station.description} | {station.location}{' '}
                </h5>
                {_.map(station?.groups, (group) => (
                  <Box
                    borderRadius="lg"
                    borderWidth="1px"
                    padding="4"
                    className="mb-6 shadow-sm mt-6 bg-gray-400"
                    key={index}
                  >
                    <Link
                      to={
                        '/dashboards/boardgroup/' +
                        station?._id +
                        '/' +
                        group?._id
                      }
                    >
                      <h2 className="text-xl font-bold ">
                        {' '}
                        <LinkUI className="font-sans font-bold">
                          {group.groupName}{' '}
                        </LinkUI>{' '}
                      </h2>{' '}
                    </Link>
                    <h5 className="text-base text-gray-700 font-sans">
                      {' '}
                      {group?.description}{' '}
                    </h5>
                    <div className="grid sm:grid-cols-1 lg:grid-cols-4">
                      <div className="my-4  px-4">
                        <Link to={'/dashboards/favorite/' + station._id}>
                          <div className="grid grid-cols-2 bg-white shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
                            <i className="fa fa-solid fa-star fa-2x text-black text-center "></i>
                            <p className=" text-black font-bold  sm:text-base lg:text-lg">
                              รายการโปรด
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="my-4  px-4">
                        <Link
                          to={
                            '/dashboards/boardgroup/' +
                            station?._id +
                            '/' +
                            group?._id
                          }
                        >
                          <div className="grid grid-cols-2 bg-white shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
                            <i className="fa fa-solid fa-chart-pie fa-2x text-black text-center "></i>
                            <p className=" text-black font-bold  text-xl">
                              เกจ
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="my-4  px-4">
                        <Link
                          to={
                            '/dashboards/graphgroup/' +
                            station?._id +
                            '/' +
                            group?._id
                          }
                        >
                          <div className="grid grid-cols-2 bg-white shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
                            <i className="fa fa-solid fa-chart-bar fa-2x text-black text-center "></i>
                            <p className=" text-black font-bold  text-xl">
                              กราฟ
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="my-4  px-4">
                        <Link
                          to={
                            '/dashboards/diagramgroup/' +
                            station?._id +
                            '/' +
                            group?._id
                          }
                        >
                          <div className="grid grid-cols-2 bg-white shadow rounded py-6 px-4 transition-transform transform hover:scale-105">
                            <i className="fa fa-solid fa-desktop fa-2x text-black text-center "></i>
                            <p className=" text-black font-bold  text-xl">
                              ไดอะแกรม
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Box>
                ))}
              </Box>
            ))}
          </div>
        </div>
      ) : (
        <div className="p-4 w-full">{t('dataAndStat.empty')}</div>
      )}
    </div>
  ) : (
    <SpinnerLoading />
  )
}
